import React from "react"
import { FaCode, FaSearchengin } from "react-icons/fa"
import { SiMaterialui } from "react-icons/si"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `Using the MERN STACK (MongoDB, Express, React.js and Nodejs) and other modern technologies to help you build full-stack web apps which are easily readable across various devices.`,
  },
  {
    id: 2,
    icon: <SiMaterialui className="service-icon" />,
    title: "web design",
    text: `I'm a full-stack web developer and designer with a passion for building beautiful things from scratch. I also leverage css frameworks such as Material-Ui or Semantic UI to build clean and elegant websites.`,
  },
  {
    id: 3,
    icon: <FaSearchengin className="service-icon" />,
    title: "SEO Deployment",
    text: `I can help deploy your website to a stable web server, configure the required SSL/TLS certificates, optimize your website for higher SEO ranking, and also add Google Analytics tracking to it.`,
  },
]
