import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"

export default ({ data }) => {
  const {
    allStrapiProject: { nodes: projects },
    allStrapiBlog: { nodes: blogs },
  } = data

  return (
    <Layout>
      <SEO
        title="Home"
        description="Hi there, I'm Bibo, a self-taught web developer. This is my portfolio site. 這裡也會分享我從文組轉職工程師的心路歷程 : )"
      />
      <Hero />
      <Services />
      <Jobs />
      <Projects projects={projects} title="featured projects" showLink />
      <Blogs blogs={blogs} title="latest articles" showLink />
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProject(
      filter: { featured: { eq: true } }
      sort: { fields: strapi_id, order: DESC }
    ) {
      nodes {
        description
        strapi_id
        id
        github
        url
        title
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
    allStrapiBlog(sort: { fields: date, order: DESC }, limit: 3) {
      nodes {
        slug
        desc
        date(formatString: "MMMM Do, yyyy")
        id
        title
        category
        image {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
