import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from "../constants/socialLinks"
import AnimatedElements from "../components/AnimatedElements"

const query = graphql`
  {
    file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <>
      <AnimatedElements />
      <header className="hero">
        <div className="section-center hero-center">
          <article className="hero-info">
            <div>
              <div className="underline"></div>
              <h1>i'm bibo</h1>
              <h4>a freelance web developer</h4>
              <Link to="/contact" className="btn contact-btn">
                contact me
              </Link>
              <SocialLinks styleClass={"hero-social-links"} />
            </div>
          </article>
          <Image fluid={fluid} className="hero-img" />
        </div>
        <a href="#services">
          <div className="scroll-down" />
        </a>
      </header>
    </>
  )
}

export default Hero
